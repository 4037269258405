@charset "utf-8";
.footer {
    &.mobile {
        display: none;
    }
    &.pad {
        display: none;
    }

    width: 100%;
    max-width: 80rem;
    margin: 0 auto;

    h4 {
        font-size: 0.875rem;
        font-weight: 600;
    }

    a.link {
        display: block;
        margin-top: 0.75rem;
        font-size: 0.875rem;
        font-weight: 400;
        text-decoration: none;
    }


    .logo {
        img {
            display: block;
            width: 7.125rem;
            height: 2.5rem;
        }

        .theme-dark {
            display: none;
        }
    }

    .address {
        max-width: 23.75rem;
        margin-top: 2.5rem;
        .line-start{
            margin-top: 0.75rem;
            height:0;
        }

        .site {
            font-size: 0.875rem;
            font-weight: 600;
        }

        .line {
            font-size: 0.875rem;
        }
    }

    .hotline {
        margin-top: 2.5rem;

        .box {
            width: 20.75rem;
            height: 4rem;
            border-radius: 0.25rem;
            border: 1px solid var(--txt-color, #0E0F10);
            background: var(--bg-color-grayer2, #F3F6F7);
            padding: 0.8125rem 1rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;

            .icon {
                width: 1.5rem;
                height: 1.5rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M13 19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19C11 18.4477 11.4477 18 12 18C12.5523 18 13 18.4477 13 19Z' fill='%230E0F10'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.75 1H17.25C18.7662 1 20 2.23375 20 3.75V20.25C20 21.7663 18.7662 23 17.25 23H6.75C5.23375 23 4 21.7663 4 20.25V3.75C4 2.23375 5.23375 1 6.75 1ZM17.25 21.5C17.9388 21.5 18.5 20.9387 18.5 20.25V3.75C18.5 3.06125 17.9388 2.5 17.25 2.5H6.75C6.06125 2.5 5.5 3.06125 5.5 3.75V20.25C5.5 20.9387 6.06125 21.5 6.75 21.5H17.25Z' fill='%230E0F10'/%3E%3C/svg%3E");
                background-size: 100% 100%;
            }

            .contact {
                .working_time {
                    font-size: 0.875rem;
                    font-weight: 600;
                }

                .divider {
                    display: inline-block;
                    width: 0.06215rem;
                    height: 0.75rem;
                    background-color: var(--control-line-color, rgba(14, 15, 16, 0.16));
                    margin: 0 0.5rem;
                }

                .tel_number {
                    margin-top: 0.25rem;
                    font-size: 0.875rem;
                }
            }
        }
    }

    .social_links {
        margin-top: 2.5rem;
        display: flex;
        flex-direction: row;
        gap: 1rem;

        .link {
            display: block;
            width: 1.5rem;
            height: 1.5rem;
            margin: 0;

            &.facebook {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M13.6888 22.8512V15.115H16.2338L16.6213 12.045H13.6888V10.0825C13.6888 9.58 13.7912 9.215 13.985 8.96375C14.19 8.7125 14.5675 8.5875 15.1488 8.5875H16.7238V5.8375C16.1875 5.7575 15.4225 5.7125 14.4412 5.7125C13.2887 5.7125 12.365 6.06625 11.6687 6.77375C10.9725 7.48125 10.63 8.485 10.63 9.775V12.0337H8.07375V15.1038H10.6187V22.8975C5.19875 22.2237 1 17.6025 1 12C1 5.93 5.9175 1 12 1C18.0825 1 23 5.9175 23 12C23 17.5 18.96 22.0413 13.6888 22.8512Z' fill='%230E0F10'/%3E%3C/svg%3E");
                background-size: 100% 100%;
            }

            &.instagram {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.5 2.5H17.5C19.7087 2.5 21.5 4.29125 21.5 6.5V17.5C21.5 19.7087 19.7087 21.5 17.5 21.5H6.5C4.29125 21.5 2.5 19.7087 2.5 17.5V6.5C2.5 4.29125 4.29125 2.5 6.5 2.5ZM6.75 12C6.75 14.895 9.105 17.25 12 17.25C14.895 17.25 17.25 14.895 17.25 12C17.25 9.105 14.895 6.75 12 6.75C9.105 6.75 6.75 9.105 6.75 12ZM17 6C17 6.5525 17.4475 7 18 7C18.5525 7 19 6.5525 19 6C19 5.4475 18.5525 5 18 5C17.4475 5 17 5.4475 17 6ZM16.25 12C16.25 14.3472 14.3472 16.25 12 16.25C9.65279 16.25 7.75 14.3472 7.75 12C7.75 9.65279 9.65279 7.75 12 7.75C14.3472 7.75 16.25 9.65279 16.25 12Z' fill='%230E0F10'/%3E%3C/svg%3E");
                background-size: 100% 100%;
            }

            &.linkedin {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M17.5 2.5H6.5C4.29125 2.5 2.5 4.29125 2.5 6.5V17.5C2.5 19.7087 4.29125 21.5 6.5 21.5H17.5C19.7087 21.5 21.5 19.7087 21.5 17.5V6.5C21.5 4.29125 19.7087 2.5 17.5 2.5ZM8.59625 17.5988H6.04V9.375H8.59625V17.5988ZM7.30125 8.36625C6.49375 8.36625 5.9725 7.79375 5.9725 7.08875C5.9725 6.38375 6.51 5.81125 7.335 5.81125C8.16 5.81125 8.66375 6.36625 8.68 7.08875C8.68 7.795 8.15875 8.36625 7.30125 8.36625ZM17.9288 17.5988H15.3725V13.0413C15.3725 11.9813 15.0025 11.2587 14.0775 11.2587C13.3713 11.2587 12.9513 11.7463 12.7662 12.2175C12.6987 12.3862 12.6825 12.6213 12.6825 12.8562V17.5988H10.1262V11.9987C10.1262 10.9725 10.0925 10.115 10.0588 9.375H12.2788L12.3962 10.5188H12.4462C12.7825 9.98 13.6062 9.19 14.9862 9.19C16.6675 9.19 17.9288 10.3175 17.9288 12.7388V17.5988Z' fill='%230E0F10'/%3E%3C/svg%3E");
                background-size: 100% 100%;
            }
        }
    }

    .app_store {
        margin-top: 2.5rem;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        a {
            display: block;

            img {
                display: block;
                width: 6.25rem;
                height: 2rem;
            }
        }
    }

    .links {

        display: flex;
        justify-content: space-between;
        padding: 1.5rem;
        gap: 5rem;


        .left {
            width: 50%;

            .top {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .address {

                }
            }
        }

        .right {
            width: 50%;

            display: flex;
            flex-direction: row;
            justify-content: space-between;


        }



        .placeholder{
            margin-top:2.5rem;
            height:0;
        }
    }

    .others {
        border-top: 1px solid var(--control-line-color, rgba(14, 15, 16, 0.16));
        padding: 1.5rem 1.5rem 2.5rem;
        display: flex;
        flex-direction: row;
        font-size: 0.75rem;
        line-height: 1rem;
        justify-content: space-between;

        .tnc {
            display: flex;
            flex-direction: row;
            gap: 1rem;

        }
    }
}


body.dark, html:has(body.dark) {
    .footer {
      

        .logo {
            .theme-light {
                display: none;
            }

            .theme-dark {
                display: block;
            }
        }

        .hotline {

            .box {

                .icon {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M13 19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19C11 18.4477 11.4477 18 12 18C12.5523 18 13 18.4477 13 19Z' fill='%23F1F0EF'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.75 1H17.25C18.7662 1 20 2.23375 20 3.75V20.25C20 21.7663 18.7662 23 17.25 23H6.75C5.23375 23 4 21.7663 4 20.25V3.75C4 2.23375 5.23375 1 6.75 1ZM17.25 21.5C17.9388 21.5 18.5 20.9387 18.5 20.25V3.75C18.5 3.06125 17.9388 2.5 17.25 2.5H6.75C6.06125 2.5 5.5 3.06125 5.5 3.75V20.25C5.5 20.9387 6.06125 21.5 6.75 21.5H17.25Z' fill='%23F1F0EF'/%3E%3C/svg%3E");
                    background-size: 100% 100%;
                }
            }
        }

        .social_links {

            .link {
                &.facebook {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M13.6888 22.8512V15.115H16.2338L16.6213 12.045H13.6888V10.0825C13.6888 9.58 13.7912 9.215 13.985 8.96375C14.19 8.7125 14.5675 8.5875 15.1488 8.5875H16.7238V5.8375C16.1875 5.7575 15.4225 5.7125 14.4412 5.7125C13.2887 5.7125 12.365 6.06625 11.6687 6.77375C10.9725 7.48125 10.63 8.485 10.63 9.775V12.0337H8.07375V15.1038H10.6187V22.8975C5.19875 22.2237 1 17.6025 1 12C1 5.93 5.9175 1 12 1C18.0825 1 23 5.9175 23 12C23 17.5 18.96 22.0413 13.6888 22.8512Z' fill='%23F1F0EF'/%3E%3C/svg%3E");
                }

                &.instagram {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.5 2.5H17.5C19.7087 2.5 21.5 4.29125 21.5 6.5V17.5C21.5 19.7087 19.7087 21.5 17.5 21.5H6.5C4.29125 21.5 2.5 19.7087 2.5 17.5V6.5C2.5 4.29125 4.29125 2.5 6.5 2.5ZM6.75 12C6.75 14.895 9.105 17.25 12 17.25C14.895 17.25 17.25 14.895 17.25 12C17.25 9.105 14.895 6.75 12 6.75C9.105 6.75 6.75 9.105 6.75 12ZM17 6C17 6.5525 17.4475 7 18 7C18.5525 7 19 6.5525 19 6C19 5.4475 18.5525 5 18 5C17.4475 5 17 5.4475 17 6ZM16.25 12C16.25 14.3472 14.3472 16.25 12 16.25C9.65279 16.25 7.75 14.3472 7.75 12C7.75 9.65279 9.65279 7.75 12 7.75C14.3472 7.75 16.25 9.65279 16.25 12Z' fill='%23F1F0EF'/%3E%3C/svg%3E");
                }

                &.linkedin {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M17.5 2.5H6.5C4.29125 2.5 2.5 4.29125 2.5 6.5V17.5C2.5 19.7087 4.29125 21.5 6.5 21.5H17.5C19.7087 21.5 21.5 19.7087 21.5 17.5V6.5C21.5 4.29125 19.7087 2.5 17.5 2.5ZM8.59625 17.5988H6.04V9.375H8.59625V17.5988ZM7.30125 8.36625C6.49375 8.36625 5.9725 7.79375 5.9725 7.08875C5.9725 6.38375 6.51 5.81125 7.335 5.81125C8.16 5.81125 8.66375 6.36625 8.68 7.08875C8.68 7.795 8.15875 8.36625 7.30125 8.36625ZM17.9288 17.5988H15.3725V13.0413C15.3725 11.9813 15.0025 11.2587 14.0775 11.2587C13.3713 11.2587 12.9513 11.7463 12.7662 12.2175C12.6987 12.3862 12.6825 12.6213 12.6825 12.8562V17.5988H10.1262V11.9987C10.1262 10.9725 10.0925 10.115 10.0588 9.375H12.2788L12.3962 10.5188H12.4462C12.7825 9.98 13.6062 9.19 14.9862 9.19C16.6675 9.19 17.9288 10.3175 17.9288 12.7388V17.5988Z' fill='%23F1F0EF'/%3E%3C/svg%3E");
                }
            }
        }
    }
}

@media screen and (max-width: 1280px) and (min-width: 750px) {
    .footer {
        &.mobile {
            display: none;
        }
        &.pc {
            display: none;
        }
        &.pad {
            display: block;
        }
        max-width: 64rem;
    }
}


@media screen and (max-width: 750px) {
    .footer {
        &.pad {
            display: none;
        }
        &.pc {
            display: none;
        }

        &.mobile {
            display: block;
        }
        .links{
            .left{
                h4{
                    margin-top:2.5rem;
                    &:first-of-type{
                        margin-top:0;
                    }
                }
            }

            .right{
                display:flex;
                flex-direction: column;
            }
        }
        
        .contact{
            margin-top:2.5rem;
            display:flex;
            flex-direction: column;
            align-items: center;
            
            .address{
                text-align: center;
            }
            .hotline{
                .contact{
                    margin-top: 0;
                    align-items: flex-start;
                }
            }
        }
        
       .others{
           margin-top:1rem;
           display: flex;
           flex-direction: column;
           padding: 1rem 0 2.5rem;
           align-items: center;
           .copyright{
                margin-top: 1.5rem;
           }
       } 
    }
}